<template>
  <div v-if="requests && requests.length > 0">
    <div
      v-for="request in requests"
      :key="request.id"
      class="float-right"
    >
      <core-toolbar-pending-request-progress-circular
        :request="request"
      />
    </div>
    <v-tooltip
      bottom
      max-width="600px"
    >
      <template #activator="{ on }">
        <v-icon
          class="hidden-xs-only"
          color="primary"
          dark
          v-on="on"
        >
          {{ infoIcon }}
        </v-icon>
      </template>
      <span v-t="'pendingRequests.loremIpsum'" />
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { INFO_ICON } from '../../../constants/app'

export default {
  data: () => ({
    infoIcon: INFO_ICON
  }),
  computed: {
    ...mapState('pendingRequests', ['requests'])
  }
}
</script>
